<script setup lang="ts">

</script>

<template>
    <footer class="p-5 sm:px-6 bg-brand-dark mt-auto flex flex-col">
        <div class="max-w-[1200px] mx-auto w-full flex flex-col lg:flex-row justify-center h-[205px] lg:h-unset justify-between">
            <NuxtLink to="/" class="self-center">
                <span class="sr-only">Bevco A/S</span>
                <NuxtImg height="70" width="70" loading="lazy" src="/logo_white.svg" alt="Logo"/>
            </NuxtLink>
            <div class="flex flex-col text-center lg:flex-row w-6/6 lg:w-2/6 justify-between text-xs text-white h-fit my-auto">
                <span>BEVCO A/S</span>
                <span class="hidden lg:inline-block">|</span>
                <span>Blomstervej 60</span>
                <span class="hidden lg:inline-block">|</span>
                <span>DK-8381 Tilst</span>
                <span class="hidden lg:inline-block">|</span>
                <span>CVR 34590222</span>
            </div>
            <div class="flex self-center" id="footer-socials">
                <NuxtLink to="https://instagram.com/bevcodk" target="_blank" class="mr-4">
                    <NuxtImg height="14" width="14" loading="lazy" src="/instagram-icon.svg" alt="Instagram"/>
                </NuxtLink>
                <NuxtLink to="https://www.facebook.com/Bevco.dk" target="_blank" class="mr-4">
                    <NuxtImg height="14" width="14" loading="lazy" src="/facebook-icon.svg" alt="Facebook"/>
                </NuxtLink>
                <NuxtLink to="https://www.linkedin.com/company/bevco-dk" target="_blank" class="mr-4">
                    <NuxtImg height="14" width="14" loading="lazy" src="/linkedin-icon.svg" alt="LinkedIn"/>
                </NuxtLink>
                <NuxtLink to="https://www.youtube.com/channel/UCw8DKQejgxnkFAbTMd1HsQA" target="_blank">
                    <NuxtImg height="14" width="14" loading="lazy" src="/youtube-icon.svg" alt="YouTube"/>
                </NuxtLink>
            </div>
        </div>
    </footer>
</template>
